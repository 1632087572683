import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {//首页界面
    path: '/',
    name: 'home',
    component: () => import('@/views/home/home.vue')
  },
  {
    path: '/pro/:dingdanNo',
    name: 'oneProject',
    component: () => import('@/views/oneProject/oneProject.vue'),
  },
  {
    path: '/publishPro',
    name: 'publishPro',
    component: () => import('@/views/publishPro/publishPro.vue'),
  },
  {
    path: '/my/:myzhz',
    name: 'my',
    component: () => import('@/views/my/my.vue'),
    redirect: '/my/:myzhz/myMenu',//默认优先重定向跳进这个页面
    children: [
      {//信息
        path: 'myMenu',
        name: 'myMenu',
        component: () => import('@/views/my/myMenu.vue')
      },
      {//充值
        path: 'myPresent',
        name: 'myPresent',
        component: () => import('@/views/my/myPresent.vue')
      },
      {//设置
        path: 'mySetting',
        name: 'mySetting',
        component: () => import('@/views/my/mySetting.vue')
      }
    ]
  },
  {//管理员界面
    path: '/gly',
    name: 'gly',
    component: () => import('@/views/gly/gly.vue')
  },
  {//用户协议
    path: '/userAgreement',
    name: 'userAgreement',
    component: () => import('@/views/components/userAgreement.vue')
  },
  {//隐私政策
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () => import('@/views/components/privacyPolicy.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


// let baiMingdan = ['/']
// router.beforeEach((to, from, next) => {
//   if (baiMingdan.includes(to.path) || sessionStorage.getItem('token')) {
//     next()
//   } else {
//     next('/')
//   }
// })

export default router
