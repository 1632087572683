import { createApp } from 'vue'
import App from './App.vue'
//导入当前项目的路由对象 
import router from './router/index.js'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import { store } from './store/index.js';

import '../style.css'

const app = createApp(App);
app.use(router)
app.use(ElementPlus, { locale: zhCn })
app.use(store)

app.mount('#app')